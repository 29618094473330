import React from 'react';
import classNames from 'classnames/bind';
import LoadingDots from '../LoadingDots';
import styles from './styles.scss';

const cx = classNames.bind(styles);

const Button = ({
  type = 'button',
  className,
  variant = 'cta',
  text,
  size = 'big',
  onClick,
  loading = false
}) => {
  const classes = cx('button', {
    'button--cta': variant === 'cta',
    'button--loading': loading,
    'button--big': size === 'big'
  });
  return (
    // eslint-disable-next-line react/button-has-type
    <button className={`${className} ${classes}`} type={type} onClick={onClick}>
      {loading ? <LoadingDots /> : <span className={styles['button--cta__text']}>{text}</span>}
    </button>
  );
};

export default Button;
