import { useCallback } from 'react';
import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

import saysALotContent from '../queries/saysALotContent';
import cardQueries from '../queries/cardQueries';
import saysALot from '../queries/saysALot';

const filter = ['event', 'news', 'press', 'thoughtLeadership', 'externalContent'];

const useSaysALotContent = () => {
  const {
    loading,
    data = {},
    fetchMore
  } = useQuery(
    gql`
            query pageQuery($limit: Int, $offset: Int) {
                ${saysALot}
                entriesConnection(limit: $limit, offset: $offset, section: [${filter.toString()}]) {
                    pageInfo {
                        first
                        last
                        totalPages
                    }
                    totalCount
                    entries {
                        __typename
                        ... on ExternalContent {
                            ${saysALotContent.externalContent}
                        }
                        ... on Event {
                            ${saysALotContent.event}
                        }
                        ... on News {
                            ${saysALotContent.news}
                        }
                        ... on Press {
                            ${saysALotContent.press}
                        }
                        ${cardQueries.article}
                    }
                }
            }
        `,
    {
      variables: { offset: 0, limit: 8 }
    }
  );

  const loadMore = useCallback(
    callback => {
      fetchMore({
        variables: {
          offset: data.entriesConnection.entries.length
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          const { entry } = fetchMoreResult;
          const { entries, pageInfo } = fetchMoreResult.entriesConnection;
          if (!entries.length) {
            return prev;
          }
          const newEntries = [...prev.entriesConnection.entries, ...entries];
          if (callback) callback(prev.entriesConnection.totalCount === newEntries.length);
          return {
            entry,
            entriesConnection: {
              __typename: prev.entriesConnection.__typename,
              pageInfo,
              totalCount: prev.entriesConnection.totalCount,
              entries: newEntries
            }
          };
        }
      });
    },
    [loading, data]
  );

  return { content: data, loadMore };
};

export default useSaysALotContent;
