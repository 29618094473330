import metaDetails from './metaDetails';

export default `
    entry (uri: "says-a-lot") {
        title
        uri
        type {
            handle
            name
        }
        ... on SaysALot {
            ${metaDetails}
            eventsModuleFeature {
                ... on FeaturesEvents {
                    eventsModule {
                        eventsLinks {
                            ... on EventsLinksInternalLink {
                                internalUrl {
                                    title
                                    uri
                                    ... on Event {
                                        leftRailIntroDetails {
                                            ... on LeftRailIntroDetailsEventDetails {
                                                whatLabel
                                                whereLabel
                                                startDateAndTime
                                            }
                                        }
                                    }
                                }
                            }
                            ... on EventsLinksExternalLink {
                                eventDate
                                eventTitle
                                eventName
                                eventLocation
                                externalUrl
                            }
                        }
                    }
                }
            }
            miniContactModule {
                contactHeader {
                    content
                }
                contactTitle
                contactEmail
                contactNumber
            }
            newsletterSignup {
                ... on NewsletterSignupNewsletterSignupEntry {
                    newsletterSignupLogo {
                        title
                        url
                        extension
                    }
                    newsletterSignupDescription
                }
            }
            cardGridTitle {
                content
            }
            specializedContactModuleSelector {
                ... on ContactModulesSpecialContact {
                    specializedContactModule {
                        ... on SpecializedContactModuleSpecializedContact {
                            contactTitle {
                                content
                            }
                            specialContact {
                                contactTitle
                                contactEmail
                                contactNumber
                            }
                            generalContact {
                                contactTitle
                                contactEmail
                                contactNumber
                            }
                        }
                    }
                }
            }
        }
    }
`;
