import React, { useState, useCallback } from 'react';

import { get } from 'helpers/utilities';
import buildGTMData from 'helpers/buildGTMData';

import CardGridComponent from 'components/CardGridComponent';
import EventsModule from 'components/EventsModule';
import SaysALotCTA from 'components/SaysALotCTA';
import Contact from 'components/Contact';
import Meta from 'components/Meta';
import NavigationThemeSetter from 'components/NavigationThemeSetter';

import useContact from '../../hooks/useContact';
import useSaysALotContent from '../../hooks/useSaysALotContent';

import styles from './styles.scss';

const SaysALot = props => {
  // Props from transporter
  const { transporterHeadRef } = props;
  // Rest
  const { active } = props;

  const { content: saysALotContent, loadMore } = useSaysALotContent();
  const saysALotData = saysALotContent.entry;
  const gtmData = buildGTMData(saysALotData);
  const contactData = useContact(saysALotData);

  const [allCardsLoaded, setAllCardsLoaded] = useState(false);
  const [fetching, setFetching] = useState(false);

  const loadMoreCallback = useCallback(done => {
    if (
      transporterHeadRef &&
      transporterHeadRef.current &&
      transporterHeadRef.current.offsetParent
    ) {
      window.requestAnimationFrame(() => {
        const transporterHeadContainer = transporterHeadRef.current.offsetParent;
        const transporterHeadHeight = transporterHeadRef.current.offsetHeight - window.innerHeight;
        Object.assign(transporterHeadContainer.style, {
          height: `${transporterHeadHeight}px`,
          minHeight: `${transporterHeadHeight}px`
        });
      });
    }
    setAllCardsLoaded(done);
    setFetching(false);
  }, []);

  const fetchMoreCards = useCallback(() => {
    if (!allCardsLoaded && !fetching) {
      setFetching(true);
      loadMore(loadMoreCallback);
    }
  }, [loadMore, loadMoreCallback, allCardsLoaded, fetching]);

  if (!saysALotData || !contactData) {
    return null;
  }

  const entriesConnection = get(saysALotContent, 'entriesConnection', null);
  const cards = get(entriesConnection, 'entries');
  const totalCards = get(entriesConnection, 'totalCount');
  const eventsModule = get(saysALotData, 'eventsModuleFeature[0].eventsModule[0]');
  const miniContactModule = get(saysALotData, 'miniContactModule[0]');
  const newsletterSignup = get(saysALotData, 'newsletterSignup');
  const title = get(saysALotData, 'cardGridTitle.content');

  // Add the Newsletter data to the cards.
  const [fistCard, ...otherCards] = cards;
  const updatedCards = [fistCard, { cardNewsletter: [{ newsletterSignup }] }, ...otherCards].map(
    card => ({ ...card, grayscale: true })
  );
  const metaDetails = {
    metaSection: get(saysALotData, 'socialMetaSection[0]'),
    entryTitle: get(saysALotData, 'title')
  };

  return (
    <>
      <Meta metaDetails={metaDetails} active={active} gtm={gtmData} />
      <NavigationThemeSetter navTheme="white" active={active}>
        <header className={`${styles.header} wrapper`}>
          <h2>{get(saysALotData, 'title')}&nbsp;—</h2>
        </header>
        {eventsModule && <EventsModule data={eventsModule} />}
        {miniContactModule && <Contact {...miniContactModule} isMinified={true} />}
        <CardGridComponent
          title={title}
          cards={updatedCards}
          totalCards={totalCards}
          layout="specialized1"
          hasAnimation={false}
          isNavWhite={true}
          isSaysALot={true}
        />
        {!allCardsLoaded && <SaysALotCTA loading={fetching} onClick={() => fetchMoreCards()} />}
        <Contact {...contactData} />
      </NavigationThemeSetter>
    </>
  );
};

export default SaysALot;
