import React from 'react';
import Button from 'components/Button';

import styles from './styles.scss';

const SaysALotCTA = ({ loading = false, onClick }) => (
  <section className={styles.saysALotCTA}>
    <Button loading={loading} text="See More" onClick={onClick} />
  </section>
);

export default SaysALotCTA;
